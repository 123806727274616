import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import LangSelector from '../components/lang-selector'
import Layout from '../components/layout'

const Title = styled.h1`
  font-size: 1.6rem;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <Helmet title='404 - Not Found' />
    <LangSelector />
    <hr />
    <Title>404 - Not Found</Title>
    <hr />
  </Layout>
)

export default NotFoundPage
